import { useCookies } from "react-cookie";
import { THEME_MODE } from "@/enum/cookie.name.enum.ts";
import { PaletteMode } from "@mui/material";
import { DEFAULT_THEME_PALETTE_NAME } from "@/theme/theme.constants.ts";

function useThemeMode(): [PaletteMode, (newValue: PaletteMode) => void] {
  const [cookies, setCookie] = useCookies([THEME_MODE]);

  const handleSet = (newMode: PaletteMode) => {
    setCookie(THEME_MODE, newMode);
  }

  return [
    cookies.themeMode || DEFAULT_THEME_PALETTE_NAME,
    handleSet
  ];
}

export default useThemeMode;
