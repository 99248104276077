"use client"

import React, { createContext, ReactNode, useContext } from 'react';

interface SiteContextType {
  siteId: number | null;
  siteName: string;
  hostName: string;
}

const SiteContext = createContext<SiteContextType | undefined>(undefined);

export const useSite = (): SiteContextType => {
  const context = useContext(SiteContext);
  if (!context) {
    throw new Error('useSite must be used within a SiteProvider');
  }
  return context;
}

interface SiteContextProviderProps {
  siteId: number | null;
  siteName: string;
  hostName: string;
  children: ReactNode;
}

export const SiteContextProvider = ({ siteId, siteName, hostName, children }: SiteContextProviderProps) => (
  <SiteContext.Provider value={{ siteId, siteName, hostName }}>
    {children}
  </SiteContext.Provider>
);
