"use client"

import React, { createContext, ReactNode, useContext } from 'react';
import { BandWorkspace } from "@/types/types.ts";

const BandWorkspaceContext = createContext<BandWorkspace | undefined>(undefined);

export const useBandWorkspace = (): BandWorkspace => {
  const context = useContext(BandWorkspaceContext);
  if (!context) {
    throw new Error('useBandWorkspace must be used within a BandWorkspaceProvider');
  }
  return context;
}

interface BandWorkspaceProviderProps {
  id: number | null;
  name: string | null;
  slug: string | null;
  children: ReactNode;
}

export const BandWorkspaceContextProvider = (
  {
    id,
    name,
    slug,
    children
  }: BandWorkspaceProviderProps) => (
  <BandWorkspaceContext.Provider value={{ id, name, slug }}>
    {children}
  </BandWorkspaceContext.Provider>
);
